
@import "../../bootstrap/variables.less";

@body-bg:					#613603;

@text-color:				#fff;
@link-color:				#f8ca12;
@link-hover-color:			#fff;
@link-hover-decoration:		none;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;
@font-size-h1:				50px;
@headings-small-color:		#000;

@thumb-width:				400px;
@thumb-height:				300px;
@thumb-margin-top:			3px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		3px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #fff;
@thumb-hover-border:		@thumb-border-width dashed #f8ca12;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
}
h1 {
	text-align: center;
	margin: 5px auto 10px;
	font-size: 18px;
	padding: 2px;
	.title{
		margin: 0 0 0 0;
		font-size: 50px;
		display: inline-block;
	}
}
.slogan {
	font-size: 18px;
	margin: 10px 10px;
	text-align: left;
	display: inline-block;
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}
#sites-list {
	li {
		&.hidden {
			display: list-item !important;
		}
		a {
			display: none;
			&.black {
				display: inline;
			}
		}
	}
}
.trades {
	margin: 20px auto 3px;
	font-size: 14px;
	font-weight: bold;
}